/**
 * @Author: qxy
 * @Date: 2019-06-11 13:37:42
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:31
 */

import http from 'ssr-common/vue/vue-http';

const api = {
    getGeneralConfig: '/api/generalConfig',
    getAboutUs: '/api/financing/operation/staticText/getByKey'
};

export default {
    getGeneralConfig(params, config) {
        return http.get(api.getGeneralConfig, {params, ...config});
    },

    getAboutUs(params) {
        return http.get(api.getAboutUs, {params});
    }
};
