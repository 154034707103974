/*
 * @Author: wuhuiwang
 * @Date: 2023-06-05 16:48:29
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-06-05 17:03:39
 */
/* eslint-disable camelcase */
import {mapState} from 'vuex';

import Webview from 'easycash/common/resource/Webview';

export default sdkList => ({
    data() {
        return {
            yqdContact: {},
            finContact: {}
        };
    },

    computed: {
        ...mapState('baseData', [
            'brand'
        ]),

        brandSdkType() {
            const brandSdkTypeList = {
                easycash: 'IDN_YQD',
                investaja: 'IDN_FIN'
            };

            return brandSdkTypeList[this.brand] || 'IDN_YQD';
        }
    },

    methods: {
        async getContact(sdk_type) {
            const sdkType = sdk_type || this.brandSdkType;
            const {data: {body} = {}} = await Webview.getContact({
                headers: {
                    'YQG-PLATFORM-SDK-TYPE': sdkType
                },
                hideToast: true
            }).catch(err => err);
            const contactInfo = body || {};
            if (sdkType === 'IDN_YQD') {
                if (!contactInfo.email) contactInfo.email = 'cs.pinjaman@easycash.id';
                this.yqdContact = contactInfo;
                this.$store.commit('contact/setYqdContact', this.yqdContact);
            } else if (sdkType === 'IDN_FIN') {
                if (!contactInfo.email) contactInfo.email = 'cs.lender@easycash.id';
                this.finContact = contactInfo;
                this.$store.commit('contact/setFinContact', this.finContact);
            }
        }
    },

    mounted() {
        if (sdkList && sdkList.length) {
            sdkList.forEach(item => {
                this.getContact(item);
            });
        } else {
            this.getContact();
        }
    }
});
