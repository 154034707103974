<!-- @Author: wuhuiwang -->
<!-- @Date: 2023-6-25 11:08:42 -->
<!-- @Last Modified by: zhaoyang -->
<!-- @Last Modified time: 2023-10-19 17:53:44 -->

<template>
    <div
        class="nav-header-wrapper"
        :class="{'nav-header-wrapper-bg': !!scrollTop || isMenuOpen}"
    >
        <div class="nav-header ec-container">
            <router-link :to="{name: 'home'}">
                <img
                    class="logo d-block d-md-none"
                    src="./img/logo-sm.png"
                >
            </router-link>
            <div class="md-right d-block d-md-none">
                <div
                    class="nav-icon"
                    :class="{open: isMenuOpen}"
                    @click="handleOpenMenu"
                >
                    <span />
                    <span />
                    <span />
                </div>

                <div
                    v-show="isMenuOpen"
                    class="menu"
                    :class="{'menu-bg': isMenuOpen}"
                >
                    <div
                        v-for="(menu, index) in menuList"
                        :key="index"
                        class="btn-tab"
                        :class="{'btn-tab-active': menu.name === $route.name}"
                    >
                        <div class="tab-title">
                            <router-link
                                v-if="menu.name !== 'tkb'"
                                :to="{name: menu.name}"
                            >
                                {{ $t(menu.name.toUpperCase()) }}
                            </router-link>
                            <div
                                v-else
                                class="tkb-content"
                            >
                                {{ menu.dynamicContent }}
                            </div>
                            <div
                                v-if="menu.subMenuList && menu.subMenuList.length"
                                class="arrow-bold"
                                :class="{'arrow-bold-expand': menu.expandStatus}"
                                @click="menu.expandStatus = !menu.expandStatus"
                            >
                                <img src="./img/arrow-bold.svg">
                            </div>
                        </div>
                        <div
                            v-if="menu.subMenuList && menu.subMenuList.length && menu.expandStatus"
                            class="tab-sub"
                        >
                            <div
                                v-for="(sub, idx) in menu.subMenuList"
                                :key="idx"
                                class="sub-title"
                                :class="{'sub-title-active': sub.name === $route.name}"
                            >
                                <router-link
                                    v-if="menu.name !== 'tkb'"
                                    :to="{name: sub.name}"
                                >
                                    {{ $t(sub.name.toUpperCase()) }}
                                </router-link>
                                <span
                                    v-else
                                    class="tkb-sub"
                                >
                                    {{ $t(sub.name.toUpperCase()) }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="language">
                        <img src="./img/frame.png">
                        <div
                            class="switch-language"
                            :class="currentLanguageImage"
                            @click="switchLanguage"
                        >
                            <span class="current-lang">{{ currentLang }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <router-link :to="{name: 'home'}">
                <img
                    class="logo d-none d-md-block"
                    src="./img/logo.png"
                >
            </router-link>
            <div class="menu-list d-none d-md-flex">
                <div
                    v-if="dataTkb && dataTkb.length > 3"
                    class="tkb"
                    @mouseover="toggleTkb(true)"
                    @mouseout="toggleTkb(false)"
                >
                    <img
                        src="./img/shield-plus.svg"
                        alt=""
                        class="shield"
                    >
                    <span class="tkb-value">{{ formatTkb(dataTkb[3]) }}</span>
                    <img
                        src="./img/arrow-tbk.svg"
                        alt=""
                        class="arrow-tbk"
                        :class="{'arrow-tbk-expand': expandTkb}"
                    >
                </div>
                <div
                    v-if="expandTkb"
                    class="tkb-wrapper"
                >
                    <div
                        v-for="(tbkData, index) in dataTkb"
                        :key="index"
                    >
                        {{ tbkData.header }}: {{ tbkData.value }}
                        <span class="edge" />
                    </div>
                </div>
                <div
                    v-for="(menu, index) in filterMenu"
                    :key="index"
                    class="btn-tab"
                    :class="{'btn-tab-active': menu.name === $route.name || (menu.subMenuList && menu.subMenuList.some(item => item.name === $route.name))}"
                >
                    <router-link :to="{name: menu.name}">
                        {{ $t(menu.name.toUpperCase()) }}
                    </router-link>
                </div>
                <div
                    class="switch-language"
                    :class="currentLanguageImage"
                    @click="switchLanguage"
                >
                    <span class="current-lang">{{ currentLang }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import getLocal from 'easycash/common/mixin/get-local';
import GeneralConfigKey from 'easycash/common/resource/config';
import {routeMap} from 'easycash/common/router/routes';

const LocalMap = {
    id: 'ID',
    en: 'EN'
};

const keys = 'app_config.idn_statistics_data';

export default {
    name: 'NavHeader',

    mixins: [getLocal],

    props: {
        blogList: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            routeMap,
            scrollTop: 0,
            local: this.getLocal(),
            isMenuOpen: false,
            expandTkb: false,
            dataTkb: null,
            menuList: [
                routeMap.home,
                routeMap.borrow,
                routeMap.lend,
                routeMap.us,
                routeMap.faq,
                {name: 'tkb', dynamicContent: '', subMenuList: []}
            ]
        };
    },

    computed: {
        filterMenu() {
            return this.menuList.filter(item => item.name !== 'tkb');
        },

        currentLang() {
            const {local} = this;

            return LocalMap[local];
        },

        currentLanguageImage() {
            const locale = this.getLocal();

            return locale === this.$i18n.fallbackLocale ? 'id-image' : 'en-image';
        }
    },

    watch: {
        $route() {
            this.isMenuOpen = false;
        },

        blogList: {
            handler(val) {
                if (val.length >= 2 && !this.menuList.some(item => item.name === 'blog')) {
                    this.menuList.push(routeMap.blog);
                }
            },
            deep: true,
            immediate: true
        }
    },

    mounted() {
        this.scrollTop = document.documentElement.scrollTop || window.pageYOffset || window.pageYOffset;
        window.onscroll = () => {
            this.scrollTop = document.documentElement.scrollTop || window.pageYOffset || window.pageYOffset;
        };

        this.menuList.forEach(item => {
            if (item.name === 'us') {
                this.$set(item, 'subMenuList', [
                    routeMap.operation,
                    routeMap.management,
                    // routeMap.activity,
                    // routeMap.socialEvent,
                    routeMap.finacicalReport,
                    routeMap.publicationComplaint
                ]);
                this.$set(item, 'expandStatus', false);
            } else if (item.name === 'faq') {
                this.$set(item, 'subMenuList', [
                    routeMap.faqGeneral,
                    routeMap.faqBorrow,
                    routeMap.faqLend,
                    routeMap.faqBeware,
                    routeMap.faqLapsSjk
                ]);
                this.$set(item, 'expandStatus', false);
            }// eslint-disable-next-line no-unused-expressions

            item?.subMenuList?.forEach(sub => {
                if (sub.name === this.$route.name) {
                    this.$set(item, 'expandStatus', true);
                }
            });
        });

        this.getData();
    },

    methods: {
        handleLangChange(value) {
            this.$i18n.locale = value;
            this.local = value;
            if (this.$route.name === 'home') {
                this.$emit('changeLocale');
            }
        },

        switchLanguage() {
            this.handleLangChange(this.currentLang === 'ID' ? 'en' : 'id');
            this.getData();
        },

        handleOpenMenu() {
            this.isMenuOpen = !this.isMenuOpen;
            if (this.isMenuOpen) {
                this.menuList.forEach(item => {
                    this.$set(item, 'expandStatus', false);
                    // eslint-disable-next-line no-unused-expressions
                    item?.subMenuList?.forEach(sub => {
                        if (sub.name === this.$route.name) {
                            this.$set(item, 'expandStatus', true);
                        }
                    });
                });
            }
        },

        toggleTkb(isHover) {
            this.expandTkb = isHover;
        },

        async getData() {
            const language = this.getLocal();

            try {
                const {data: {body: {configs: [{configValue}]}}} = await GeneralConfigKey.getGeneralConfig({keys, language});
                this.dataTkb = configValue.slice(0, 4);
            } catch (error) {
                //ignore 
            }

            const tkbMenu = this.menuList.find(item => item.name === 'tkb');
            tkbMenu.dynamicContent = this.getDynamicContent('tkb');
            tkbMenu.subMenuList = this.dataTkb.map(tkb => ({
                name: `${tkb.header}: ${tkb.value}`
            }));
        },

        formatTkb(tkb) {
            return this.$t('home.header.tkb', [tkb.header, tkb.value]);
        },

        getDynamicContent(menuName) {
            if (menuName === 'tkb') {
                return this.dataTkb.length > 3 ? `${this.dataTkb[3].header}: ${this.dataTkb[3].value}` : '';
            }

            return '';
        },
    }
};
</script>

<style lang="scss" scoped>
.nav-header-wrapper {
    width: 100%;
    height: 0.8rem;

    // transition: all .3s ease-in-out;
    background-color: transparent;
    box-sizing: border-box;
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;

    &-bg {
        box-shadow: 0 1px 2px 0 rgba(148, 186, 217, 0.16);
        background-color: #fff;
    }

    .nav-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;
        white-space: nowrap;

        a {
            display: contents;
        }

        .logo {
            width: auto;
            height: 0.32rem;
        }

        .menu-list {
            align-items: center;
            position: relative;

            .tkb {
                display: flex;
                align-items: center;
                background: rgba(26, 86, 50, 0.3);
                margin-right: 0.1rem;
                padding: 0 0.07rem;
                border-radius: 12px;
                font-family: Mozaic Hum;
                font-weight: 400;
                color: white;
                font-size: 0.14rem;
                cursor: pointer;

                .shield {
                    align-items: center;
                    width: 0.16rem;
                    height: 0.16rem;
                }

                .tkb-value {
                    margin-top: 0.02rem;
                }

                .arrow-tbk {
                    width: 0.1rem;
                    height: 0.1rem;
                    margin-left: 2px;
                    transition: all 0.2s ease-in-out;
                    transform: rotate(-90deg);

                    &-expand {
                        transform: rotate(0deg);
                    }
                }
            }

            .tkb-wrapper {
                position: absolute;
                border-radius: 12px;
                top: 0.36rem;
                left: 0.05rem;
                padding: 0.15rem;
                background: linear-gradient(90deg, #3f3f3f 0%, #2d2d2d 100%);
                color: white;
                font-size: 0.14rem;
                font-weight: 400;
                font-family: Mozaic Hum;

                .edge {
                    position: absolute;
                    background: linear-gradient(90deg, #3b3b3b 0%, #2d2d2d 100%);
                    width: 0.2rem;
                    height: 0.2rem;
                    top: 4px;
                    left: 50%;
                    transform: translate(-50%, -50%) rotate(45deg);
                    z-index: -9;
                }
            }

            .btn-tab {
                margin: 0 0.1rem;
                display: flex;
                align-items: center;

                a {
                    display: flex;
                    font-size: 0.14rem;
                    font-family: "Quicksand";
                    font-weight: 400;
                    line-height: 1;
                    color: #090909;
                }

                &-active {
                    a {
                        color: #000;
                        font-weight: 700;
                        display: flex;
                    }
                }

                &:hover {
                    opacity: 0.6;
                    transition: all 0.25s;

                    a {
                        color: #005732;
                    }
                }
            }

            .change-lang {
                display: flex;
                align-items: center;
                padding: 0 0.08rem;
                border: 1px solid #1e1e1e;
                border-radius: 0.3rem;
                position: relative;
                cursor: pointer;
                margin-left: 0.1rem;
                font-size: 0.12rem;

                span {
                    line-height: 1;
                    margin: 0.04rem 0 0.02rem;
                }

                img {
                    margin-left: 0.035rem;
                    width: 0.055rem;
                    height: 0.03rem;
                    transition: transform 0.2s ease-in;
                }

                .lang-menu {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 0.6rem;
                }

                &:hover {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .md-right {
            display: block;

            .nav-icon {
                position: relative;
                width: 0.16rem;
                height: 0.16rem;
                transform: rotate(0deg);
                transition: 0.5s ease-in-out;
                cursor: pointer;

                &.open {
                    span {
                        &:nth-child(1) {
                            top: 0.1rem;
                            transform: rotate(135deg);
                        }

                        &:nth-child(2) {
                            opacity: 0;
                            left: -0.4rem;
                        }

                        &:nth-child(3) {
                            top: 0.1rem;
                            transform: rotate(-135deg);
                        }
                    }
                }

                span {
                    display: block;
                    position: absolute;
                    height: 0.03rem;
                    width: 100%;
                    background: black;
                    border-radius: 0.02rem;
                    opacity: 1;
                    left: 0;
                    transform: rotate(0deg);
                    transition: 0.25s ease-in-out;

                    &:nth-child(1) {
                        top: 0;
                    }

                    &:nth-child(2) {
                        top: 0.065rem;
                    }

                    &:nth-child(3) {
                        top: 0.13rem;
                    }
                }
            }

            .menu {
                position: absolute;
                width: 100vw;
                height: calc(100vh - 100%);
                top: 100%;
                left: 0;
                background: #fff;
                border-top: 1px solid #dedede;
                overflow: auto;

                .btn-tab {
                    padding: 0.1rem 0.2rem;
                    margin-bottom: 0.1rem;

                    &-active {
                        background: #f8f8f8;

                        a {
                            font-weight: 700 !important;
                            color: #000 !important;
                        }
                    }

                    &:first-child {
                        margin-top: 0.3rem;
                    }

                    .tab-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        a,
                        .tkb-content {
                            color: #090909;
                            font-size: 0.14rem;
                            font-family: "Quicksand";
                            font-weight: 500;
                            letter-spacing: -0.75px;
                            width: 80%;

                            // &.router-link-active {
                            //     font-weight: 700;
                            // }
                        }

                        .arrow-bold {
                            width: 20%;

                            img {
                                width: 0.05rem;
                                height: 0.1rem;
                                transition: all 0.2s ease-in-out;
                                float: right;
                            }

                            &-expand {
                                img {
                                    transform: rotate(90deg);
                                }
                            }
                        }
                    }

                    .tab-sub {
                        .sub-title {
                            opacity: 0.7;
                            margin-top: 0.1rem;

                            .tkb-sub {
                                color: #090909;
                                font-size: 0.14rem;
                                font-family: "Quicksand";
                                line-height: 0.1875rem;
                                opacity: 1;
                                font-weight: 400;

                                &:hover {
                                    color: #005732;
                                }
                            }

                            a {
                                color: #090909;
                                font-size: 0.14rem;
                                font-family: "Quicksand";
                                line-height: 0.1875rem;
                                opacity: 0.7;
                                font-weight: 400;

                                &:hover {
                                    color: #005732;
                                }
                            }

                            &-active {
                                opacity: 1;
                                background: #f8f8f8;
                                padding: 0.03rem 0;

                                a {
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }

                .language {
                    margin: 0.2rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 0.2rem;

                    img {
                        width: 1.09rem;
                        height: auto;
                    }
                }
            }
        }
    }

    .switch-language {
        position: relative;
        width: 0.46rem;
        height: 0.2rem;
        border-radius: 0.3rem;
        outline: 0;
        cursor: pointer;
        user-select: none;
        transition: 400ms all;
        border: none;
        background-color: transparent;
        border: 0.01rem solid #999;
        -webkit-tap-highlight-color: transparent;
        color: #000;
        font-size: 0.12rem;
        font-family: Mozaic HUM;
        font-weight: 600;
        line-height: 0.12rem;
        margin-left: 0.1rem;

        &::before {
            position: absolute;
            display: block;
            width: 0.18rem;
            height: 0.18rem;
            content: "";
            border: 0.01rem solid #999;
            border-radius: 50%;
            transform: translate(0, 0);
            transition: 300ms all;
            background-repeat: no-repeat;
            background-size: cover;
        }

        &.id-image {
            .current-lang {
                position: absolute;
                left: 0.08rem;
                margin-top: 0.01rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &::before {
                right: -0.01rem;
                background-image: url("./img/id.jpg");
            }
        }

        &.en-image {
            .current-lang {
                position: absolute;
                right: 0.05rem;
                margin-top: 0.01rem;
                top: 50%;
                transform: translateY(-50%);
            }

            &::before {
                left: -0.01rem;
                background-image: url("./img/en-us.jpg");
            }
        }
    }
}
</style>
