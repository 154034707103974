var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "index" },
    [
      _vm.isHome
        ? _c(
            "div",
            [
              _c("warning-banner", {
                class: { "warning-banner-hide": _vm.isBannerHidden },
                style: { height: _vm.headerTop },
                on: { bannerClosed: _vm.handleBannerClosed },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("nav-header", {
        style: { top: _vm.isHome ? _vm.headerTop : "0" },
        attrs: { "blog-list": _vm.blogList },
      }),
      _vm._v(" "),
      _c("router-view", {
        staticClass: "view",
        style: {
          marginTop: _vm.isHome && !_vm.isBannerHidden ? "1.4rem" : "0",
        },
        attrs: { "blog-list": _vm.blogList },
      }),
      _vm._v(" "),
      _c("ec-footer"),
      _vm._v(" "),
      _vm.isShowComplaint
        ? _c("img", {
            staticClass: "pendant",
            attrs: { src: require("./complaint/img/pendant.png") },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "complaint" })
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }