var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nav-header-wrapper",
      class: { "nav-header-wrapper-bg": !!_vm.scrollTop || _vm.isMenuOpen },
    },
    [
      _c(
        "div",
        { staticClass: "nav-header ec-container" },
        [
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("img", {
              staticClass: "logo d-block d-md-none",
              attrs: { src: require("./img/logo-sm.png") },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "md-right d-block d-md-none" }, [
            _c(
              "div",
              {
                staticClass: "nav-icon",
                class: { open: _vm.isMenuOpen },
                on: { click: _vm.handleOpenMenu },
              },
              [_c("span"), _vm._v(" "), _c("span"), _vm._v(" "), _c("span")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.isMenuOpen,
                    expression: "isMenuOpen",
                  },
                ],
                staticClass: "menu",
                class: { "menu-bg": _vm.isMenuOpen },
              },
              [
                _vm._l(_vm.menuList, function (menu, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "btn-tab",
                      class: {
                        "btn-tab-active": menu.name === _vm.$route.name,
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "tab-title" },
                        [
                          menu.name !== "tkb"
                            ? _c(
                                "router-link",
                                { attrs: { to: { name: menu.name } } },
                                [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(_vm.$t(menu.name.toUpperCase())) +
                                      "\n                        "
                                  ),
                                ]
                              )
                            : _c("div", { staticClass: "tkb-content" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(menu.dynamicContent) +
                                    "\n                        "
                                ),
                              ]),
                          _vm._v(" "),
                          menu.subMenuList && menu.subMenuList.length
                            ? _c(
                                "div",
                                {
                                  staticClass: "arrow-bold",
                                  class: {
                                    "arrow-bold-expand": menu.expandStatus,
                                  },
                                  on: {
                                    click: function ($event) {
                                      menu.expandStatus = !menu.expandStatus
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("./img/arrow-bold.svg"),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      menu.subMenuList &&
                      menu.subMenuList.length &&
                      menu.expandStatus
                        ? _c(
                            "div",
                            { staticClass: "tab-sub" },
                            _vm._l(menu.subMenuList, function (sub, idx) {
                              return _c(
                                "div",
                                {
                                  key: idx,
                                  staticClass: "sub-title",
                                  class: {
                                    "sub-title-active":
                                      sub.name === _vm.$route.name,
                                  },
                                },
                                [
                                  menu.name !== "tkb"
                                    ? _c(
                                        "router-link",
                                        { attrs: { to: { name: sub.name } } },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm.$t(sub.name.toUpperCase())
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    : _c("span", { staticClass: "tkb-sub" }, [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.$t(sub.name.toUpperCase())
                                            ) +
                                            "\n                            "
                                        ),
                                      ]),
                                ],
                                1
                              )
                            }),
                            0
                          )
                        : _vm._e(),
                    ]
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "language" }, [
                  _c("img", { attrs: { src: require("./img/frame.png") } }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "switch-language",
                      class: _vm.currentLanguageImage,
                      on: { click: _vm.switchLanguage },
                    },
                    [
                      _c("span", { staticClass: "current-lang" }, [
                        _vm._v(_vm._s(_vm.currentLang)),
                      ]),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("router-link", { attrs: { to: { name: "home" } } }, [
            _c("img", {
              staticClass: "logo d-none d-md-block",
              attrs: { src: require("./img/logo.png") },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "menu-list d-none d-md-flex" },
            [
              _vm.dataTkb && _vm.dataTkb.length > 3
                ? _c(
                    "div",
                    {
                      staticClass: "tkb",
                      on: {
                        mouseover: function ($event) {
                          return _vm.toggleTkb(true)
                        },
                        mouseout: function ($event) {
                          return _vm.toggleTkb(false)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "shield",
                        attrs: {
                          src: require("./img/shield-plus.svg"),
                          alt: "",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "tkb-value" }, [
                        _vm._v(_vm._s(_vm.formatTkb(_vm.dataTkb[3]))),
                      ]),
                      _vm._v(" "),
                      _c("img", {
                        staticClass: "arrow-tbk",
                        class: { "arrow-tbk-expand": _vm.expandTkb },
                        attrs: { src: require("./img/arrow-tbk.svg"), alt: "" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.expandTkb
                ? _c(
                    "div",
                    { staticClass: "tkb-wrapper" },
                    _vm._l(_vm.dataTkb, function (tbkData, index) {
                      return _c("div", { key: index }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(tbkData.header) +
                            ": " +
                            _vm._s(tbkData.value) +
                            "\n                    "
                        ),
                        _c("span", { staticClass: "edge" }),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.filterMenu, function (menu, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "btn-tab",
                    class: {
                      "btn-tab-active":
                        menu.name === _vm.$route.name ||
                        (menu.subMenuList &&
                          menu.subMenuList.some(function (item) {
                            return item.name === _vm.$route.name
                          })),
                    },
                  },
                  [
                    _c("router-link", { attrs: { to: { name: menu.name } } }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t(menu.name.toUpperCase())) +
                          "\n                "
                      ),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "switch-language",
                  class: _vm.currentLanguageImage,
                  on: { click: _vm.switchLanguage },
                },
                [
                  _c("span", { staticClass: "current-lang" }, [
                    _vm._v(_vm._s(_vm.currentLang)),
                  ]),
                ]
              ),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }